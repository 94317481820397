.MapPopup {
	:global {
		.mmrgl-popup-tip {
			border-top-color: var(--vkui--color_background_modal);
			border-bottom-color: var(--vkui--color_background_modal);
		}

		.mmrgl-popup-content {
			background: var(--vkui--color_background_modal);
			border-radius: var(--vkui--size_card_border_radius--regular);
			cursor: pointer;
		}
	}
}
